/* Global font family for the entire app */
body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4; /* Subtle background for contrast */
}

/* Container for the event slider */
.event-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* Wrap cards for responsiveness */
  overflow: hidden; /* Hides overflowing content */
  position: relative;
  width: 100%;
  padding: 40px 20px; /* Space around the slider */
  box-sizing: border-box;
}

/* Event card styling */

/* Event icon styling */
.event-icon {
  
  color: #333;
  margin-bottom: 10px;
  transition: color 0.3s ease;
}

/* Event name styling */
.event-name {
  font-size: 1.5rem; /* Prominent size for names */
  font-weight: bold;
  color: #444;
  margin: 10px 0;
  text-transform: capitalize;
}

/* Event description styling */
.describe {
  font-size: 1rem; /* Smaller than name */
  color: #666;
  font-style: italic;
  margin: 5px 0;
}

/* Link styling */
.eventlink {
  font-size: 1rem;
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
  margin-top: 10px;
}

.eventlink:hover {
  color: darkblue;
}
.text-hover:hover {
  color: darkblue;
  text-decoration: underline;
}
/* Hover effect for event cards */

  .event-icon {
    font-size: xx-large;
  }

  .event-name {
    font-size: 1.2rem; /* Adjust font size */
  }

  .describe {
    font-size: 0.9rem; /* Smaller description font */
  }


.event-slider-container {
  width: 100%;
  padding: 20px 0; /* Optional: To add some space around the slider */
}


.event-slider .slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}




.event-icon {
 
  margin-bottom: 10px;
}
.event-icon:hover {
  color: red; /* Change color to red on hover */
 
}
.event-name {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.describe {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
}

.eventlink {
  color: #007bff;
  text-decoration: none;
}

/* Flex container for carousel */
.carousel-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin-top: 80px;
  gap: 20px;
}

/* Left button container */
.left-button,
.right-button {
  flex: 0 0 auto;
  color: #0056b3;
}

.event-list-container{
  display: flex;
  flex-direction: column;
}

/* Center event box */
.event-box {
  
  display: flex;
  align-items: center;
  justify-content: center;
margin-top: 40px;
width: 70%;
  background-color: #e9ecef;
  
  border-radius: 50px;
}

/* Event content styles */
.event-content {
  text-align: center;
}

.event-content i {
  color: #007bff;
  margin-bottom: 15px;
}

.event-content h3 {
  font-size: 1.5rem;
  margin: 0;
  color: #333;
}

.event-content p {
  font-size: 1rem;
  color: #666;
  margin-top: 10px;
}

/* Arrow button styles */
.arrow {
 color: #007bff;
  
  border: none;
  padding: 10px 20px;
  font-size: 1.5rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.arrow:hover {
  background-color: yellow;
}




/* Container for the video section */
.video-container {
  text-align: center;
  margin: 20px;
  padding: 20px;
 
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

/* Button styling */
.video-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 12px 25px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s, transform 0.2s ease-in-out;
  box-shadow: 0 4px 6px rgba(0, 123, 255, 0.3);
}

.video-button:hover {
  background-color: #ffee00; /* Yellow hover effect */
  transform: scale(1.05); /* Slightly scale up on hover */
}

.video-button:focus {
  outline: none;
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
}

/* Video iframe container */
.video-iframe {
  width: 100%;
  max-width: 800px;
  height: 450px;
  margin: 20px auto;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Shadow around iframe */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.video-iframe:hover {
  transform: scale(1.03); /* Zoom effect on hover */
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.3);
}

/* Responsive styles */
@media (max-width: 768px) {
  .video-button {
    padding: 10px 20px;
    font-size: 14px;
  }

  .video-iframe {
    width: 90%;
    height: 300px;
  }
}

/* Text below video section */
.video-container p {
  font-size: 16px;
  color: #333;
  margin-top: 20px;
  line-height: 1.6;
  font-weight: 300;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

/* Optional: Add a fade-in effect for video section */
.video-container {
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}












/* Event List Styles */
.carousel-container {
  
  height: 100%;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-hover:hover {
  transform: scale(1.1);
  color: #0056b3;
  
}


.event-name {
  font-size: 1.5em;
  margin: 10px 0;
}

.describe {
  font-size: 1em;
  color: #555;
  text-align: center;
  margin-bottom: 10px;
}

.eventlink a {
  color: #007bff;
  text-decoration: none;
}

.swiper-container {
  width: 100%;
  max-width: 800px;
  height: 400px;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide  {
  width: 250px; /* Adjust based on your design */
  height: 350px;
  background-color: #f5f5f5;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.swiper-button-next, .swiper-button-prev {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 50%;
  padding: 10px;
}

.swiper-button-next:hover, .swiper-button-prev:hover {
  background-color: rgba(0, 0, 0, 0.8);
}


.eventlink:hover{
  color: red;
}


.img{
  height: 50px;
}



/* Container for the event slider */
.event-slider {
  display: flex;
  flex-wrap: wrap; /* Enable wrapping for responsiveness */
  justify-content: center; /* Center the items */
  gap: 20px; /* Space between the cards */
  padding: 20px;
  box-sizing: border-box;
}

/* Individual event card */
.event-card {
  
  width: calc(50% - 20px); /* Two cards per row, accounting for gap */
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
 
}
.event-icon-wrapper-left{
 
  
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 41%;
 
}


.event-icon-wrapper{
 
  color: #337ab7;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 6rem;
  height: 6rem;
  -webkit-transform: rotate(47deg);  /* Rotate the div to create the diamond shape */
  -moz-transform: rotate(47deg);
  -ms-transform: rotate(47deg);
  -o-transform: rotate(47deg);
  transform: rotate(47deg);
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
 
}
.event-icon-wrapper:hover,.event-icon-wrapper:active {
  border-color: blue; /* Change border color on hover */
}

/* This targets the icon inside event-icon-wrapper when the wrapper is hovered */
.event-icon-wrapper:hover i,.event-icon-wrapper:active i {
  color: blue; /* Change icon color to blue */
}

.event-icon-wrapper-right{
 
  
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 41%;
 
}

.event-icon-rounder{
 
  
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 10rem;

 
}
@media (max-width:768px){
  .event-icon-wrapper{
 
    color: #337ab7;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 6rem;
    height: 4rem;
    -webkit-transform: rotate(47deg);  /* Rotate the div to create the diamond shape */
    -moz-transform: rotate(47deg);
    -ms-transform: rotate(47deg);
    -o-transform: rotate(47deg);
    transform: rotate(47deg);
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
   
  }
}

.event-icon-wrapper:hover,.event-icon-wrapper:active{


  
  transform: rotate(45deg);
  border: 3px solid blue;

 
 
}
.event-icon-wrapper i{
  -webkit-transform: rotate(-47deg);  /* Rotate the icon in the opposite direction */
  -moz-transform: rotate(-47deg);
  -ms-transform: rotate(-47deg);
  -o-transform: rotate(-47deg);
  transform: rotate(-47deg);
}

.event-icon-wrapper:active{
  border-color: blue;
  background-color: rgba(0, 0, 255, 0.2);
  
 
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .event-card {
    width: 100%; /* Full-width for smaller devices */
  }
}





/* Initial state for the div */
.zoom-in {
  display: flex;
  flex-direction: row;
  opacity: 0;
  transform: scale(0.8);
  transition: transform 1s ease, opacity 1s ease;
}

/* State when the div is visible (zoom-in effect) */
.zoom-in.visible {
  display: flex;
  flex-direction: row;
  opacity: 1;
  transform: scale(1);
}


