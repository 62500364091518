.App{
  width: 100%;
}

html, body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
}
/* General Content Styling */
.content {
  margin-top: 70px; /* space for navbar */
  text-align: center;
}

h2 {
  font-size: 1.8em;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 0.5em;
  color:#6d0a0a;
}

p {
  font-size: 1.2em;
  margin-bottom: 2em;
  
}

.event-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}



.event-icon {
  
  color: #3498db;
  margin-bottom: 10px;
}




.prizes {
  animation: slideFromRight 0.4s ease-out forwards; /* Trigger animation from left */
  padding-bottom: 3px;
  background-size: 0 1px;
  position: relative;
  display: inline;
  top: 0;
  padding-bottom: 0;
  text-decoration: none;
  background-image: linear-gradient(#000,#000);
  background-repeat: no-repeat;
  background-position: 0 80%;
  transition: all .4s ease; 
}

/* Animation for the prize text (left to center) */
@keyframes slideFromRight {
  0% {
      transform: translateX(-100%); /* Start from the left (outside view) */
      opacity: 0;
  }
  100% {
      transform: translateX(0); /* End at the center */
      opacity: 1;
  }
}

.events {
  animation: slideFromRight 0.4s ease-out forwards; /* Trigger animation from right */
}

/* Animation for the event text (right to center) */
@keyframes slideFromRight {
  0% {
      transform: translateX(100%); /* Start from the right (outside view) */
      opacity: 0;
  }
  100% {
      transform: translateX(0); /* End at the center */
      opacity: 1;
  }
}

/* Home.css */
header {
  font-family: 'Roboto', sans-serif;
  /*position: fixed;  Fixes the header to the top */
  top: 0; /* Aligns it to the top */
  left: 0; /* Aligns it to the left */
  right: 0; /* Aligns it to the right */
  background-color: rgba(0, 0, 0, 0.664); /* Semi-transparent background */
  color: white; /* Text color */
  z-index: 10; /* Ensures it is above other content */
  transition: background-color 0.3s; /* Smooth transition */
  padding: 5px 10px; /* Decreased padding */
  font-size: 14px; /* Adjust font size as needed */
  font-family: 'Roboto', sans-serif;
  
  display: flex; /* Optional: Align items in the header */
  align-items: center; /* Optional: Vertically center content */
  justify-content: space-between; 
}

.header.scrolled {
  background-color: gold; /* Solid background when scrolled */
}

.container {  display: flex;

  display: flex; /* Flex layout for navbar */
  justify-content: space-between; /* Space out children */
  align-items: center; /* Center items vertically */
  justify-content: space-between; 
}

.navbar-header {
  display: flex; /* Flex layout for the header */
  align-items: center; /* Center items vertically */

  width:100%;
  color:white !important;
  justify-content:space-between;
}


@media (max-width:768px) {
  .navlinks{
    display: none;
  }
  
}
/* Default style for larger screens */
.navbar {
  width: 200px;
  margin-top: 2%;
}

/* Style for smaller screens */
@media screen and (max-width: 768px) {
  .navbar {
    width: 150px;
   
  }
}


.navlinks{
  display: flex;
  align-items:right;
  color:white !important;
  
}
/* Ensure the hamburger icon only shows on mobile */
.hamburger {
  margin-top: 20%;
  display: none; /* Hide on desktop by default */
  cursor: pointer;
  position: absolutely; /* Position it absolutely within the container */
  right: 20px; /* Space from the right side */
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Adjust to center */
  z-index: 15; /* Make sure it stays above other elements */
  padding:20px;
 margin-left: 30px;
  width: 30px; /* Adjust width if necessary */
}

.bar {
  display: block;
  width: 100%; /* Full width of the container */
  height: 3px;
 
  background-color: #333;
  margin: 4px 0;
  transition: 0.4s;
}

.close-icon {
  color: white; /* Change to white if sidebar background is dark */
  
   /* Adjust the top position */
  
  cursor: pointer;
}
.sidebar.show {
  opacity:1;
  transform: translateX(0);
  display: flex;
  flex-direction:column;
  justify-content:space-between;
  
}

.sidebar-nav {
  background-color: #333;
}

.sidebar-nav li {
  margin: 10px 0;
}

.sidebar-nav li a {
  color: white;
  text-decoration: none;
  font-size: 1.2em;
  font-family: 'Roboto', sans-serif;
}

/* Show hamburger menu on smaller screens */
@media screen and (max-width: 768px) {
  .hamburger {
    
    display: flex; /* Show hamburger icon */
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    cursor: pointer;
    color: #fff; /* Change color as needed */
  }

  .nav-links {
    display: none; /* Hide links initially */
    flex-direction: column; /* Stack links vertically */
    position: relative;
    top: 60px; /* Align below the header */
    right: 0;
    background-color: white; /* Dark background */
    width: 100%; /* Full width on mobile */
    align-items: center;
    padding: 10px 0;
  }

  /* Add a class to show the nav links when open */
  .nav-links.open {
    display: flex; /* Display the links */
  }

  .nav-links a {
    color: rgb(238, 14, 14); /* Link color */
    padding: 10px;
    text-decoration: none;
    width: 100%; /* Full width for links */
    text-align: center; /* Center the text */
  }

  
}
.event-link:hover {
  color: orange; /* Hover color */
}

@media screen and (max-width: 768px) {
  /* Display hamburger icon */
  .hamburger {
    display: flex; /* Show hamburger on small screens */
    font-size: 30px; /* Icon size */
    color: red; /* Icon color */
  }

  /* Show nav-links when "open" class is added */
  .nav-links.open {
    display: flex; /* Show nav links when open class is added */
  }

  .nav-links a {
    color: red; /* Link color */
    padding: 10px;
    text-decoration: none;
    width: 100%; /* Make links full width */
    text-align: center; /* Center the text */
  }

  .nav-links a:hover {
    background-color: #555; /* Darken the background on hover */
  }
}

/* Styling for larger screens (when not mobile) */
@media screen and (min-width: 769px) {
  /* Default nav links display horizontally */
  .nav-links {
    display: flex;
    flex-direction: row; /* Arrange links in a row */
    justify-content: flex-end; /* Align links to the right */
  }

  /* Hide hamburger icon on larger screens */
  .hamburger {
    display: none;
  }
}




















/* Default styling for larger screens */
.full-width-image {
  position: relative;
  width: 100%; /* Set to 100% for larger screens */
  overflow: hidden;
}

.full-width-image img {
  width: 100%; /* Full width of container */
  height: auto; /* Maintain aspect ratio */
}

/* Container for the image and overlay */
.image-container {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
}

/* Mobile-specific styling */
@media (max-width: 768px) {
  .full-width-image {
    width: 100%; /* Increase width for mobile view */
  }
}




/* Text overlay */
.text-overlay {
  margin-top: 20px; /* Add spacing between the image and text */
  

  text-align: left;
  font-size: 1.2em;
  color: #333;
  word-wrap: break-word; /* To make sure the text wraps nicely */
 
  text-shadow: 2px 2px 3px blue; /* Text shadow effect */
  animation: typing 5s steps(40, end) forwards, blink-caret 0.75s step-end infinite; /* Typing and cursor blink animations */
}

/* Blinking text effect for "Innovations" */
.blinking {
  font-family: 'Roboto', sans-serif;
  color:black; /* Text color */
  text-align: left;
  font-size: 29px;
   border-right: 3px solid rgb(247, 243, 243) !important; /* Cursor effect */
  text-shadow: 2px 2px 3px gray;
  
    display: inline-block;
    white-space: normal; /* Ensures the text can wrap */
    word-wrap: break-word; /* Breaks the words if necessary */
    line-height: 2.5; /* Optional: Adjust line height for better readability */
  /*animation: blink 2s infinite !important;        Blinking animation */
  animation-delay: 5s; /* Start blinking after typing effect */
  animation: typing 5s steps(40, end) forwards, blink-caret 0.75s step-end infinite; 
}
@media (max-width: 768px) {
  .blinking {
    font-size: 22px; /* Adjust font size for smaller screens */
    line-height: 2; /* Adjust line height for better readability on mobile */
    animation: typing 2s steps(30, end) forwards, blink-caret 0.75s step-end infinite !important; /* Adjust animation timing */
  }
}
/* Typing animation */
@keyframes typing {
  from { width: 0; } /* Start with no width */
  to { width: 100%; } /* Expand to full width */
}

/* Blinking caret */
@keyframes blink-caret {
  50% { border-color: transparent; } /* Cursor blink effect */
}

/* Blinking animation */
@keyframes blink {
  0%, 100% { opacity: 1; } /* Fully visible */
  50% { opacity: 0; } /* Invisible for blink */
}

/* Responsive behavior for mobile devices */
@media (max-width: 600px) {
  .text-overlay {
    font-size: 20px; /* Smaller font size */
    white-space: normal; /* Allow wrapping */
   
    font-family: 'Roboto', sans-serif
  }
}

/* Optional: Semi-transparent background */
.semi-transparent-bg {
  background-color: rgba(255, 255, 255, 0.7); /* White background with opacity */
  padding: 20px; /* Padding */
  border-radius: 10px; /* Rounded corners */
}

/* Overlay styling to position on the right side */
.overlay {
  position: absolute; /* Overlay the prizes section */
  top: 0;
  right: 100; /* Aligns overlay to the right */
  width: 100%; /* Adjust width as needed */
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Align content to the left within the overlay */
  
  pointer-events: none; /* Allows clicks on the image */
}

/* Styling for the prizes section */
.prizes {
  color:red;   /* Semi-transparent background for readability */
  text-align: center;
 font-family:initial;
  border-radius: 8px;
  max-width: 100%;
  margin-top: 270px;
}

/* Styling for the text */
.prizes h1 {
  display: block;
  color: #000;
  margin-top: 320px;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
}



.prizes p {
  font-size: 1rem;
  
}







/* Container to center the content */
.centered-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 30vh; /* Full viewport height for vertical centering */
  text-align: center; /* Center text inside the section */
  margin-bottom: 300px;
}

/* Events section styling */


.events h1 {
  display: block;
  color: #000;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
}



.events p {
  font-size: 1rem;
  text-align: center;
  
}








/* General header styling */
#navigation {
 /* position: fixed;*/
  
  background-color: Black;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content:space-between;
  position: fixed;
  height: 110px;

}


/* Navbar links for larger screens */
.nav-links {
  display: flex;
  gap: 20px;
  
}

.nav-links a {
  color: white;/*home text color*/
  text-decoration: none;
  font-size: 2rem;
  font-weight: 500;
  display:flex;
  justify-content:space-between;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  color: azure !important;
  
}
.home-text{
  margin-top: 30px;
}

.hamburger .bar {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 4px 0;
  transition: all 0.3s;
}

/* Sidebar styling */
.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 250px;
  height: 35%;
  background-color: #333;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: translateX(100%);
  transition: transform 0.3s ease, opacity 0.3s ease;
  z-index: 10;
  opacity:0;
}

.sidebar.show {
  transform: translateX(0);
  
}

.sidebar-nav {
  list-style-type: none;
  padding: 0;
}

.sidebar-nav li {
  padding: 15px;
  text-align: center;
}

.sidebar-nav li a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
}

/* Responsive styles */
@media (max-width: 768px) {
  .nav-links {
    display: none; /* Hide nav-links on mobile */
  }

  .hamburger {
    display: flex; /* Show hamburger icon on mobile */
  }
}

/* Styles for desktop view */
@media (min-width: 769px) {
  .sidebar {
    display: none; /* Hide sidebar on desktop */
  }
  
}
.title-bar{
  display:flex;
  justify-content:space-between;
  margin-left: 2px;
 
}


.event-heading::after {
  content: "";
  display: block;
  
  margin: 10px auto 0; /* Spacing between text and line */
}
.line{
  display: inline-block;
  width: 20%; /* Adjust the line width as needed */
  height: 1px; /* Thickness of the line */
  background-color: blue; /* Line color */

}













/* In your App.css or main styles */
html {
  scroll-behavior: smooth;
}
.event-link {
  cursor: pointer;
  color: white;
  text-decoration: underline;
}
button {
  border-radius: 10px;
}


.carousel {
  position: relative;
  width: 100%;
  background-size: cover;
  height: 100%;
  overflow: hidden; /* Hide overflowing images */
}



.image-container {
  display: flex;
  transition: transform 1s ease-in-out; /* Smooth transition for sliding effect */
}

.event-image {
  width: 100%; /* Ensures the images take full width of the carousel */
  display: flex;
  height: 600px;
  object-fit: cover;
  flex-shrink: 0; /* Prevent images from shrinking */
  flex-grow: 0; /* Prevent images from growing */
  flex-basis: 100%; /* Make each image take full width of the container */
}
@media (max-width: 768px) { 
  /* Adjust for mobile screens */
  .event-image {
    height: 300px;       /* Adjust height for mobile devices */
    object-fit: cover;   /* Maintain aspect ratio while covering the container */
  }
}
@media (min-width: 769px) {
.text-container {
  font-size: 24px;
  margin-top: 20px;
  margin-left: 15px;
  
  margin-right: 10px;
  font-family: Arial, Helvetica, sans-serif;
  white-space: nowrap;
  color: rgba(8, 8, 8, 0.966);
  text-align: left;
  border-radius: 20px;
  font-weight: bold;
  line-height: 1.8;
  width: auto;
}

/* Hide all spans initially */
.text-container span {
  visibility: hidden;
  display: inline-block;
  overflow: hidden;
  display: flex;
  white-space: nowrap;
  border-right: 2px solid transparent; /* Blinking cursor effect (hidden by default) */
}
}
/* Typing animation */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .text-container {
    font-size: .9rem; /* Adjust font size */
    margin: 20px 10px; /* Top/bottom and left/right margins */
    font-family: Arial, Helvetica, sans-serif;
    color: rgba(8, 8, 8, 0.966);
    text-align: left; /* Align text to the left */
    
    font-weight: bold;
    line-height: 1.8; /* Line height for readability */
    display: flex; /* Flexbox layout */
    flex-wrap: wrap; /* Allow spans to wrap to the next line */
    gap: 10px; /* Space between spans */
    overflow: hidden; /* Ensures hidden text before animation */
 
  
  animation: typing 4s steps(40, end) forwards, blink-caret 0.5s step-end infinite;
  }
  
  .text-container span {
    flex: 1 1 auto; /* Flexible width for spans */
    min-width: 200px; /* Prevent spans from shrinking too small */
    margin-bottom: 10px; /* Add space below each span */
    visibility: visible; /* Ensure spans are visible */
    overflow: hidden; /* Hide overflowing content */
  }
  .text-container span.visible {
    visibility: visible;
    animation: typing 3s steps(30) forwards; /* Typing effect */
  }
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
  @keyframes blink-caret {
    from,
    to {
      border-right-color: transparent;
    }
    50% {
      border-right-color: black;
    }
  }
  
  }
  



/* Show each line sequentially */
.text-container span.visible {
  visibility: visible;
  animation: typing 3s steps(30) forwards; /* Typing effect */
}

 .scroll {
  
  transform: translateY(50px); /* Initially moved down */
  transition: opacity 0.5s ease, transform 0.5s ease;

  font-size: 20px;
}

/* Vertical animation */


/* Horizontal animation - initially off-screen */
.scroll {
  opacity: 0;
  transform: translateX(100px); /* Initially moved right */
  transition: opacity 0.5s ease, transform 0.5s ease;
}

/* When the element is in viewport */
.scroll.visible {
  opacity: 1;
  transform: translateX(0); /* Move to original position */
  margin-top: 350px;
  
}

.carousel {
  touch-action: pan-y; /* Allow vertical scrolling while swiping horizontally */
}











/* Default state for H1: no underline */
.a-link:hover{
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 10px;
  text-decoration: underline;
  color: #000;
  font-family: 'Courier New', Courier, monospace;
}





.wave-text {
  display: inline-flex;
  gap: 2px; /* Adjust spacing between letters if needed */
}

.wave-text span {
  display: inline-block;
  animation: wave-animation 1.5s ease-in-out infinite;
  animation-delay: calc(var(--index) * 0.1s);
  transform-origin: bottom center;
}

/* Keyframes for wave effect */
@keyframes wave-animation {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}





.loading-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000; /* or your preferred background */
  color: #fff; /* Text color */
  font-family: Arial, sans-serif;
}

.spinner {
  border: 8px solid rgba(255, 255, 255, 0.2);
  border-top: 8px solid #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.image-style{
  text-align: center;
  margin-top: 15px;
}

.image-style-img{
  height: 50%;
  width: 50%;
}


.fade-in {
  opacity: 1; /* Fade in effect */
}

.fade-out {
  opacity: 0; /* Fade out effect */
}


























.text-scroller.zoom-in {
  transform: scale(1.2); /* Adjust the scale value to control the zoom */
}







/*Carousel the image in home*/
.carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: auto;
 
  padding: 20px;
  
}

.image-container {
  display: flex;
  justify-content: center; /* Centers the image horizontally */
  align-items: flex-start; /* Allows margin-top: auto to work */
  flex: 1;
}
.image {
  width: 100%;
  height: 800px;
  border-radius: 5px;
  
}

.navigation {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 15px;
}

.arrow {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1.2rem;
  transition: background-color 0.3s;
}

.arrow:hover {
  background-color: #0056b3;
}

.left-arrow {
  margin-right: 10px;
}

.right-arrow {
  margin-left: 10px;
}











.image {
  width: 100%;
  height: 700px;
  border-radius: 10px;
}

.dots {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.dot {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: #ccc;
  border-radius: 50%;
  cursor: pointer;
}

.dot.active {
  background-color: #007bff;
}








/* Container styling */
.welcome-banner {
  font-family: Arial, sans-serif;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  color: black;
  margin-top: 50px;
}
.letter{
  display: inline-block;
  background-color: #3498db; /* Choose a color for the box */
  color: white;  /* Text color inside the box */
  padding: 5px;
  margin-right: 5px;
  border-radius: 5px; /* Optional: rounded corners */
}
.letters{
  display: inline-block;
 
  color: white;  /* Text color inside the box */
  padding: 7px;
  margin-right: 5px;
  border-radius: 5px; /* Optional: rounded corners */
}

.space {
  display: inline-block;
  width: 20px; /* Adjust space between words */
}


/* Letter styling */
.letter,.letters {
  display: inline-block;
  opacity: 0; /* Initially hidden */
  animation: blink 5s linear infinite; /* Infinite loop */
}

/* Keyframes for blinking */
@keyframes blink {
  0% {
    opacity: 0; /* Hidden */
  }
  10% {
    opacity: 1; /* Visible */
  }
  100% {
    opacity: 1; /* Stay visible till the end of the cycle */
  }
}

/* Staggered animation delays for each letter */
.letter:nth-child(1) {
  animation-delay: 0.1s;
}
.letter:nth-child(2) {
  animation-delay: 0.3s;
}
.letter:nth-child(3) {
  animation-delay: 0.5s;
}
.letter:nth-child(4) {
  animation-delay: 0.7s;
}
.letter:nth-child(5) {
  animation-delay: 0.9s;
}
.letter:nth-child(6) {
  animation-delay: 1.1s;
}
.letter:nth-child(7) {
  animation-delay: 1.3s;
}
.letter:nth-child(8) {
  animation-delay: 1.5s;
}
.letter:nth-child(9) {
  animation-delay: 1.7s;
}
.letter:nth-child(10) {
  animation-delay: 1.9s;
}
.letter:nth-child(11) {
  animation-delay: 2.1s;
}
.letters:nth-child(12) {
  animation-delay: 2.3s;
}
.letters:nth-child(13) {
  animation-delay: 2.5s;
}
.letters:nth-child(14) {
  animation-delay: 2.7s;
}
.letters:nth-child(15) {
  animation-delay: 2.9s;
}
.letters:nth-child(16) {
  animation-delay: 3.1s;
}
.letters:nth-child(17) {
  animation-delay: 3.3s;
}
.letters:nth-child(18) {
  animation-delay: 3.5s;
}
