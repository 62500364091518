@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;700&display=swap');

.sponsors-grid {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-top: 2%;
  }
  
  .sponsors-container {
  
    justify-content: space-between; /* Spread out the sections */
    width: 100%;
    max-width: 1200px; /* Optional, to limit the container width */
  }
  
  .sponsor-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1; /* Allow each section to grow evenly */
    margin-top: 2%;
  }
  
  .sponsor-section.left {
    
    align-items: center;
    
  }
  
  .sponsor-section.center {
    justify-content: center; /* Align center */
    margin-top: 5%;
  }
  
  .sponsor-section.right {
    justify-content: flex-end; /* Align right */
  }
  
  .sponsor {
    margin: 0 15px; /* Margin between logos */
    transition: transform 0.3s ease;
  }
  
  .image1,.image2{
    width: 100px; /* Adjust size as needed */
    height: 150px;
    border: 2px solid hsla(0, 15%, 83%, 0.384); /* Border around logos */
    border-radius: 15px;
    padding: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .image1:hover,.image:hover {
    transform: scale(1.1); /* Zoom effect on hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
    
  }
  @media (min-width: 768px) {
    .sponsor-text {
      font-size: 35px;
    }
    .image1 {
      width: 250px;
      height: 150px;
    }
    .image2{
      width: 350px;
      height: 250px;
    }
    .thanks{
      margin-top: 9%;
      font-size: 3rem ;
      text-align: center;
    }  
    .sponsor-text {
      margin-top: 10px;
      
      
      font-weight: bold;
      color: blue; /* Neutral color */
      
      text-transform: uppercase; /* Convert text to uppercase */
      letter-spacing: 1px; /* Add some spacing between letters */
    }
     .sponsors-container {
    display: flex;
    }
    .sponsor-section.center .sponsor-text{
      font-size: 3.5rem;
    }
  }
  /* Responsive Design */
  @media (max-width: 768px) {
    .sponsors-container {
      flex-direction: row;
      justify-content: space-between;
      align-items: center; /* Stack sections vertically */
     
      

    }
    .sponsor-section.center .sponsor-text{
      font-size: 1.5rem;
    }
    
    .thanks{
      margin-top: 36%;
      text-align: center;
    }
    .sponsors-grid {
      flex-direction: row;
    }
    .sponsor-text {
      font-size: 16px;
    }
    .sponsor-section.left1 {
     margin-left: 8%;
    }
  
    .sponsor-section {
      margin-bottom: 20px;
    }
    .image1 {
      width: 150px;
      height: 150px;
    }
    .image2{
      width: 250px;
      height: 200px;
    }
    
  }
  .thanks{
    font-family: 'Cinzel', serif;
    font-weight: bold;
    color: blue; /* Neutral color */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);  
    text-transform: uppercase; /* Convert text to uppercase */
    letter-spacing: 1px; /* Add some spacing between letters */

  }

  .sponsor-text {
    margin-top: 10px; 
    font-family: 'Cinzel', serif;
    
    font-weight: bold;
    color: #8B0000; /* Neutral color */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    text-transform: uppercase; /* Convert text to uppercase */
    letter-spacing: 1px; /* Add some spacing between letters */
  }
  
  .image1,.image2{
  
  
  border: 4px solid transparent;
  border-radius: 10px;
  transition: transform 0.3s, border-color 0.3s;
  animation: borderAnimation 6s infinite;
}

.image1:hover {
  transform: scale(1.1);
  
}

/* Sponsors.css */

/* Style for the sponsor text */
.sponsor-text {
  
  font-weight: bold;
  margin-top: 18px;
  
}

/* Each letter (span) will blink */
.sponsor-text span {
  opacity: 0;
  animation: blinkAnimation 8s infinite;
  display: inline-block;
}

/* Animation for blinking */
@keyframes blinkAnimation {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

/* Delay for each letter, based on the index */
.sponsor-text span:nth-child(1) {
  animation-delay: 0s;
}
.sponsor-text span:nth-child(2) {
  animation-delay: 0.2s;
}
.sponsor-text span:nth-child(3) {
  animation-delay: 0.4s;
}
.sponsor-text span:nth-child(4) {
  animation-delay: 0.6s;
}
.sponsor-text span:nth-child(5) {
  animation-delay: 0.8s;
}
.sponsor-text span:nth-child(6) {
  animation-delay: 1s;
}
/* Continue adding delays for more characters as needed */


/* Animations */
@keyframes borderAnimation {
  0%, 100% {
    border-color: transparent;
  }
  25% {
    border-color: #f00;
  }
  50% {
    border-color: #00f;
  }
  75% {
    border-color: #0f0;
  }
}

@keyframes blinkAnimation {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

/* Link styles */
a {
  text-decoration: none;
  color: inherit;
}


