/* Footer Container */
.footer {
    background-color: #000000;
    color: #fff;
    text-align: center;
    padding: 15px 0;
    margin-top: auto;
    font-size: 14px;
  }
  
  /* Footer Flexbox for Proper Alignment */
  .footer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px; /* Space between phone and email */
    flex-wrap: wrap;
  }
  
  /* Footer Item Styling */
  .footer-item {
    display: flex;
    align-items: center;
    gap: 8px; /* Space between icon and text */
  }
  
  /* Phone and Email Text */
  .footer a {
    text-decoration: none;
  }
  
  /* Phone Number Color */
  .footer a[href^="tel"] {
    color: #ffcc00; /* Phone number in yellow */
  }
  
  /* Email Text Color */
  .footer a[href^="mailto"] {
    color: #ffcc00; /* Email text in yellow */
  }
  
  /* Hover Effects */
  .footer a:hover {
    text-decoration: underline;
  }
  
  /* Email Icon Styling */
  .email-icon {
    color: red; /* Email icon in red */
    font-size: 20px;
    transition: transform 0.3s ease-in-out;
  }
  
  /* Animation on Hover */
  .email-icon:hover {
    transform: rotate(15deg) scale(1.2);
  }
  
  /* Responsive Design */
  
  /* Tablet */
  @media (max-width: 768px) {
    .footer {
      font-size: 12px;
      padding: 10px 0;
    }
  
    .footer-container {
      gap: 30px; /* Reduce gap between phone and email */
    }
  
    .email-icon {
      font-size: 18px;
    }
  }
  
  /* Mobile */
  @media (max-width: 480px) {
    .footer {
      font-size: 10px;
      padding: 8px 0;
    }
  
    .footer-container {
      flex-direction: column; /* Stack items vertically */
      gap: 15px; /* Space between stacked items */
    }
  
    .footer-item {
      justify-content: center;
    }
  
    .email-icon {
      font-size: 16px;
    }
  }
  