/* General styles */
.schedule-container {
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  margin: auto;
  overflow-x: auto;
}

.schedule {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  margin-top: 20px;
  
}

/* Table styles */
.schedule-table {
  width: 100%;
  border-collapse: collapse;
  font-family: 'Arial', sans-serif;
  margin-top: 10px;
}

.schedule-table th {
  background-color: #007bff;
  color: white;
  font-weight: bold;
  padding: 12px;
  text-align: center;
  border-bottom: 2px solid #dddddd; /* Horizontal line below header */
}

.schedule-table td {
  padding: 10px;
  text-align: center;
  border-top: 1px solid #dddddd; /* Horizontal line between rows */
  transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Remove vertical lines */
.schedule-table td,
.schedule-table th {
  border-left: none;
  border-right: none;
}

/* Highlight today's event */
.highlight {
  background-color: #ffe08a;
  animation: pulse 1s infinite alternate;
}

.highlight-day {
  font-weight: bold;
  color: #d9534f;
}

.highlight-event {
  font-weight: bold;
  color: #007bff;
}

/* Table row hover effect */
.schedule-table tr:hover {
  background-color: #f1f1f1;
  transform: scale(1.02);
  cursor: pointer;
}
@media (max-width: 768px) {
  .schedule-table tr:hover {
    background-color: #f1f1f1;
    transform: scale(1.02);
    cursor: pointer;
  }
}

/* Button styles */
.schedule-table button {
  padding: 6px 12px;
  color: white;
  background-color: #28a745;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.schedule-table button:hover {
  background-color: #218838;
}

/* Link styles */
.schedule-table a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.schedule-table a:hover {
  text-decoration: underline;
}

/* Animation */
@keyframes pulse {
  0% {
    background-color: #ffe08a;
  }
  100% {
    background-color: #ffc107;
  }
}
