

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #808080;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}











/* App.css */



@keyframes gradientAnimation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.particles {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.particle {
  position: absolute;
  background-color: red;
  border-radius: 50%;
  animation: fall 10s linear infinite;
}

@keyframes fall {
  0% {
    transform: translateY(-100vh); /* Start above the viewport */
    opacity: 1;
  }
  100% {
    transform: translateY(100vh); /* End below the viewport */
    opacity: 0; /* Fade out */
  }
}






/* App.css */



/* Animated gradient background */
/* App background color */
.App {
   /* background-color: bisque; Set background color for the entire page */
  min-height: 100vh;         /* Ensure the background color covers the full height */
  position: relative;        /* To allow layering with other content */
  z-index: 0;
}

/* Animated Gradient Layer */
.animated-gradient {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background: linear-gradient(45deg, #ff7e5f, #feb47b, #6a11cb, #2575fc); /* Gradient animation */
  background-size: 300% 300%;
  animation: gradientAnimation 20s ease infinite;
  z-index: -1; /* Ensure it stays behind the content */
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


/* Particles animation - Make sure it's behind content but still visible */
#tsparticles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -3; /* Behind the gradient */
}